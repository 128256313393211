import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Layout
import Header from "../layout/header/header1";

// Elements
import PhotosContent from "../elements/photos-content";

// Images
import bannerImg from '../../images/banner/banner1.jpg';
import { withTranslation } from 'react-i18next';

	
class Photos extends Component{
	componentDidMount() {
		
	}
	render()
	{
		const { t } = this.props;
		return (
			<>
				
				<Header />
				
				<div className="page-content">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bannerImg+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{ t('Achievements_Photos_Title') }</h1>
							 </div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li><Link to="/">{ t('Achievements_Photos_LinkHome') }</Link></li>
								<li>{ t('Achievements_Photos_LinkPortf') }</li>
							</ul>
						</div>
					</div>
					
					<div className="content-block">
						<div className="section-area section-sp1 gallery-bx">
							<div className="container">
								<PhotosContent />
							</div>
						</div> 
					</div> 
				</div>
			</>
		);
	}
}

export default Photos =  withTranslation()(Photos);